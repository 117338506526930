
import {defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {DocumentField, FormTrainingItemRegionAnchor, FormTrainingRegionAnchor} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import FormPositionTrainingTable from "@/apps/squeeze/components/FormPositionTrainingTable.vue";

export default defineComponent({
	name: "FormTrainingRegionAnchorItem",
	components: {
		FormPositionTrainingTable,
		InputText,
		Dropdown,
		Button,
	},
	props: {
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		regionAnchorOfFormTraining: {
			type: Object as PropType<FormTrainingRegionAnchor>,
			default: () => ({}),
		},
		booleanOptions: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	emits: [
		'testRegionAnchor',
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Selected Indicator ID */
		const indicatorId = ref<number>(0);

		/** Current regin anchor */
		const regionAnchor = reactive<FormTrainingRegionAnchor>({
			id: undefined,
			description: '',
			keyWordPattern: '',
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			ignoreWhiteSpaces: false,
		});

		/** Show Loading on load data */
		const loading = ref(false);

		/** All region anchors */
		const allRegionAnchors = ref<FormTrainingRegionAnchor[]>([]);

		/** All indicators */
		const allIndicators = ref<FormTrainingItemRegionAnchor[]>([]);

		/** Form Training API endpoint */
		const formTrainingApi = ClientManager.getInstance().squeeze.formTraining;

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/** Set first indicator */
		const setFirstIndicator = () => {
			if (allIndicators.value && allIndicators.value.length && allIndicators.value[0].id) {
				indicatorId.value = allIndicators.value[0].id;
			}
		}

		/** Reset region anchor */
		const resetRegionAnchor = () => {
			Object.assign(regionAnchor, {
				id: undefined,
				description: '',
				keyWordPattern: '',
				keyWordRegion: {
					page: 0,
					x0: 0,
					y0: 0,
					x1: 0,
					y1: 0,
				},
				ignoreWhiteSpaces: false,
			});

			setFirstIndicator();
		}

		/** Get all region anchors */
		const getAllRegionAnchors = () => {
			loading.value = true;

			formTrainingApi.getFormItemRegionAnchors(props.documentClassId, indicatorId.value)
				.then(data => {
					allRegionAnchors.value = data;
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Train or retrain a region anchor */
		const trainRegionAnchor = () => {
			loading.value = true;

			let promise;
			if (regionAnchor.id) {
				promise = formTrainingApi.retrainFormItemRegionAnchor(props.documentClassId, regionAnchor.id, regionAnchor, true);
			} else {
				promise = formTrainingApi.trainFormItemRegionAnchor(props.documentClassId, indicatorId.value, regionAnchor, false);
			}

			promise
				.then(() => {
					getAllRegionAnchors();
					resetRegionAnchor();
				}).catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				}).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Test a region anchor
		 * @param rowData
		 */
		const testRegionAnchor = (rowData: any) => {
			rowData.indicatorId = indicatorId.value;
			emit("testRegionAnchor", rowData);
		}

		/**
		 * Change a region anchor
		 * @param data
		 */
		const changeRegionAnchor = (data: any) => {
			Object.assign(regionAnchor, data);
		}

		/**
		 * Deletes a region anchor
		 * @param data
		 */
		const deleteRegionAnchor = (data: any) => {
			loading.value = true;
			formTrainingApi.deleteFormItemRegionAnchor(props.documentClassId, data.id)
				.then(() => {
					getAllRegionAnchors();
					resetRegionAnchor();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all Indicators */
		const getAllIndicators = () => {
			loading.value = true;
			formTrainingApi.getFormTableItemIndicator(props.documentClassId, trainingKey.value!.value!)
				.then(data => {
					allIndicators.value = data;

					setFirstIndicator();
					getAllRegionAnchors();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Set marker regions by clicking a row
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: any; index: number}) => {
			const row = event.data;
			emit("onMarkRegion", [row.keyWordRegion]);
		}

		onMounted(() => {
			Object.assign(regionAnchor, props.regionAnchorOfFormTraining);
			resetRegionAnchor();
			getAllIndicators();
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		watch(() => props.regionAnchorOfFormTraining.keyWordPattern, () => {
			regionAnchor.keyWordPattern = props.regionAnchorOfFormTraining.keyWordPattern;
			regionAnchor.keyWordRegion = props.regionAnchorOfFormTraining.keyWordRegion;
		})

		return {
			t,
			toast,
			trainingKey,
			indicatorId,
			regionAnchor,
			loading,
			allRegionAnchors,
			allIndicators,
			onFocusField,
			resetRegionAnchor,
			trainRegionAnchor,
			testRegionAnchor,
			changeRegionAnchor,
			deleteRegionAnchor,
			getAllIndicators,
			onMarkRegion,
		}
	},
});
