
import {defineComponent, onBeforeMount, onMounted, PropType, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {
	DocumentField,
	DocumentFieldValue,
	DocumentTable,
	DocumentTableColumn,
	FormTrainingItemField,
	FormTrainingItemRegionAnchor,
	FormTrainingRegionAnchor
} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "FormTrainingTestTable",
	components: {
		InputText,
		Dropdown,
		Button,
		DataTable,
		Column,
	},
	props: {
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		documentId: {
			type: Number,
			required: true,
		},
		testFilter: {
			type: Object as PropType<any>,
			default: () => ({}),
		},
	},
	emits: [
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Selected Indicator ID */
		const indicatorId = ref<number>(0);

		/** Selected region anchor ID */
		const regionAnchorId = ref<number>(0);

		/** Selected item field ID */
		const itemFieldId = ref<any>(0);

		/** All indicators */
		const allIndicators = ref<FormTrainingItemRegionAnchor[]>([]);

		/** All region anchors */
		const allRegionAnchors = ref<FormTrainingRegionAnchor[]>([]);

		/** All item fields */
		const allItemFields = ref<FormTrainingItemField[]>([]);

		/** Current table columns used by the indicator */
		const currentTableColumns = ref<DocumentTableColumn[]>([]);

		/** Test data */
		const testData = ref<DocumentFieldValue[]>([]);

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Form Training API endpoint */
		const formTrainingApi = ClientManager.getInstance().squeeze.formTraining;

		/** Get test data of line items*/
		const getTestData = () => {
			loading.value = true;
			if (!indicatorId.value) {
				return;
			}
			documentApi.testLineFormTraining(props.documentId, trainingKey.value!.value!, indicatorId.value, regionAnchorId.value, itemFieldId.value ? itemFieldId.value.id : itemFieldId.value)
				.then((data: DocumentFieldValue[]) => {
					testData.value = data;
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Reset test filter */
		const resetTestFilter = () => {
			indicatorId.value = 0;
			regionAnchorId.value = 0;
			itemFieldId.value = 0;

			allRegionAnchors.value = [];
			allItemFields.value = [];
			testData.value = [];
		}

		/**
		 * Click a row to mark
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: any; index: number}) => {
			emit("onMarkRegion", [event.data.boundingBox]);
		}

		/** Get all Indicators */
		const getAllIndicators = () => {
			loading.value = true;
			formTrainingApi.getFormTableItemIndicator(props.documentClassId, trainingKey.value!.value!)
				.then(data => {
					allIndicators.value = data;
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Get all table columns
		 * We need this for the value description for the dropdown of item fields
		 */
		const getAllTableColumns = () => {
			const currentIndicator = allIndicators.value.find(indicator => indicator.id === indicatorId.value);
			documentClassApi.getDocumentClassTableById(props.documentClassId, currentIndicator!.tableId!)
				.then((data: DocumentTable) => {
					currentTableColumns.value = data.columns || [];
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get all region anchors */
		const getAllRegionAnchors = () => {
			loading.value = true;
			if (!indicatorId.value) {
				return;
			}
			// reset after change of indicator
			if (regionAnchorId.value && props.testFilter && props.testFilter.indicatorId !== indicatorId.value) {
				regionAnchorId.value = 0;
				itemFieldId.value = 0;
				allItemFields.value = [];
			}

			formTrainingApi.getFormItemRegionAnchors(props.documentClassId, indicatorId.value)
				.then(data => {
					allRegionAnchors.value = data;
					getAllTableColumns();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all item fields */
		const getAllItemFields = () => {
			loading.value = true;
			// reset after change of region anchor
			if (itemFieldId.value && props.testFilter && props.testFilter.regionAnchorId !== regionAnchorId.value) {
				itemFieldId.value = 0;
			}

			formTrainingApi.getFormItemFields(props.documentClassId, regionAnchorId.value)
				.then(data => {
					allItemFields.value = data;
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Get item field value description
		 * @param slotProp
		 */
		const getItemFieldValueDescription = (slotProp: any) => {
			if (!slotProp.id) return '';
			const itemField = allItemFields.value.find((itemField: any) => itemField.id === slotProp.id);
			if (!itemField) return t('Squeeze.DocumentClasses.ItemFields') + "-ID: " + slotProp.id;

			let column = allRegionAnchors.value.find((column: any) => column.id === regionAnchorId.value);
			if (props.testFilter && props.testFilter.columns) {
				column = props.testFilter.columns.find((column: any) => column.id === itemField.columnId);
			}
			if (!column) return itemField.valuePattern + " | " + t('Squeeze.DocumentClasses.TableColumns') + "-ID: " + itemField.columnId;
			return itemField.valuePattern + " | " + column.description;
		}

		/**
		 * Get item field description
		 * @param slotProps
		 */
		const getItemFieldDescription = (slotProps: any) => {
			let column = currentTableColumns.value.find((column: any) => column.id === slotProps.columnId);
			if (props.testFilter && props.testFilter.columns) {
				column = props.testFilter.columns.find((column: any) => column.id === slotProps.columnId);
			}
			if (!column) return slotProps.valuePattern + " | " + t('Squeeze.DocumentClasses.TableColumns') + "-ID: " + slotProps.columnId;
			return slotProps.valuePattern + " | " + column.description;
		}

		onMounted(() => {
			if (!trainingKey.value!.value) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				return;
			}

			getAllIndicators();

			if (props.testFilter.regionAnchorId) {
				indicatorId.value = props.testFilter.indicatorId;
				regionAnchorId.value = props.testFilter.regionAnchorId;
				itemFieldId.value = props.testFilter;
				getAllItemFields();
			} else if (props.testFilter.indicatorId) {
				indicatorId.value = props.testFilter.indicatorId;
				regionAnchorId.value = props.testFilter.id;
				getAllItemFields();
			} else {
				indicatorId.value = props.testFilter.id;
			}

			getAllRegionAnchors();
			getTestData();
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		return {
			t,
			toast,
			trainingKey,
			loading,
			indicatorId,
			regionAnchorId,
			itemFieldId,
			allIndicators,
			allRegionAnchors,
			allItemFields,
			testData,
			getTestData,
			resetTestFilter,
			onMarkRegion,
			getAllIndicators,
			getAllRegionAnchors,
			getAllItemFields,
			getItemFieldValueDescription,
			getItemFieldDescription,
		}
	},
});
