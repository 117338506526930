
import {defineComponent, PropType} from "vue";
import {useI18n} from "vue-i18n";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {DocumentTable} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "FormPositionTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		tableOrigin: {
			type: String,
			default: '',
		},
		tableData: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		tableId: {
			type: Number,
			default: 0,
		},
		tables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
	},
	emits: [
		'testData',
		'changeData',
		'deleteData',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/**
		 * Test an indicator
		 * or a region anchor
		 * or an item field
		 * @param rowData
		 */
		const testData = (rowData: any) => {
			emit("testData", rowData);
		}

		/**
		 * Change an indicator
		 * @param rowData
		 */
		const changeData = (rowData: any) => {
			emit("changeData", rowData);
		}

		/**
		 * Deletes an indicator
		 * @param rowData
		 */
		const deleteData = (rowData: any) => {
			emit("deleteData", rowData);
		}

		/**
		 * Click a row to mark
		 * @param event
		 */
		const onMarkRegion = (event: any) => {
			emit("onMarkRegion", event);
		}

		/**
		 * Get column name
		 * @param columnId
		 */
		const getColumnName = (columnId: number) => {
			const table = props.tables.find(table => table.id === props.tableId);
			if (table && table.columns) {
				const column = table.columns.find(column => column.id === columnId);
				return column ? column.description : columnId;
			}
			return columnId;
		}

		return {
			t,
			testData,
			changeData,
			deleteData,
			onMarkRegion,
			getColumnName,
		}
	},
});
