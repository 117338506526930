
import {computed, defineComponent, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {
	DocumentField,
	DocumentTable, FormTrainingItemField,
	FormTrainingItemRegionAnchor,
	FormTrainingRegionAnchor
} from "@dex/squeeze-client-ts";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormTrainingItemIndicator from "@/apps/squeeze/components/FormTrainingItemIndicator.vue";
import FormTrainingRegionAnchorItem from "@/apps/squeeze/components/FormTrainingRegionAnchorItem.vue";
import FormTrainingItemFields from "@/apps/squeeze/components/FormTrainingItemFields.vue";
import FormTrainingTestTable from "@/apps/squeeze/components/FormTrainingTestTable.vue";

export default defineComponent({
	name: "FormPositionTraining",
	components: {
		FormTrainingTestTable,
		FormTrainingItemFields,
		FormTrainingRegionAnchorItem,
		FormTrainingItemIndicator,
		TabView,
		TabPanel,
	},
	props: {
		documentId: {
			type: Number,
			required: true,
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		tables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		indicatorOfFormTraining: {
			type: Object as PropType<FormTrainingItemRegionAnchor>,
			default: () => ({}),
		},
		regionAnchorOfFormTraining: {
			type: Object as PropType<FormTrainingRegionAnchor>,
			default: () => ({}),
		},
		itemFieldOfRegionInFormTraining: {
			type: Object as PropType<FormTrainingItemField>,
			default: () => ({}),
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current active tab option */
		const activeTabOption = ref(0);

		/** All tab options of a file */
		const tabOptions = ref([
			{id: 0, value: t('Squeeze.Training.Indicator')},
			{id: 1, value: t('Squeeze.Training.Region') + ' ' + t('Squeeze.Training.Anchor')},
			{id: 2, value: t('Squeeze.Training.Region') + ' ' + t('Squeeze.Training.Fields')},
			{id: 3, value: t('Squeeze.Training.Test')},
		]);

		/** List of boolean options */
		const booleanOptions = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/**  Test filter */
		const testFilter = ref<any>({});

		/**
		 * Test data
		 * @param rowData
		 */
		const testingData = (rowData: any) => {
			activeTabOption.value = 3;
			testFilter.value = rowData;
		}

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/**
		 * Set marker regions by clicking a row
		 * @param region
		 */
		const onMarkRegion = (region: any) => {
			emit("onMarkRegion", region);
		}

		return {
			t,
			toast,
			activeTabOption,
			tabOptions,
			booleanOptions,
			testFilter,
			testingData,
			onFocusField,
			onMarkRegion,
		}
	},
});
