import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78779233"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-p-2 p-ml-2 p-component",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "scroll-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTrainingItemIndicator = _resolveComponent("FormTrainingItemIndicator")!
  const _component_FormTrainingRegionAnchorItem = _resolveComponent("FormTrainingRegionAnchorItem")!
  const _component_FormTrainingItemFields = _resolveComponent("FormTrainingItemFields")!
  const _component_FormTrainingTestTable = _resolveComponent("FormTrainingTestTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.activeTabOption,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabOption) = $event)),
      lazy: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabOptions, (tab) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: tab.value
          }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(tab.value), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (tab.id === 0)
                  ? (_openBlock(), _createBlock(_component_FormTrainingItemIndicator, {
                      key: 0,
                      trainingKeyField: _ctx.trainingKeyField,
                      documentClassId: _ctx.documentClassId,
                      tables: _ctx.tables,
                      indicatorOfFormTraining: _ctx.indicatorOfFormTraining,
                      booleanOptions: _ctx.booleanOptions,
                      onTestIndicator: _ctx.testingData,
                      onOnFocusField: _ctx.onFocusField,
                      onOnMarkRegion: _ctx.onMarkRegion
                    }, null, 8, ["trainingKeyField", "documentClassId", "tables", "indicatorOfFormTraining", "booleanOptions", "onTestIndicator", "onOnFocusField", "onOnMarkRegion"]))
                  : (tab.id === 1)
                    ? (_openBlock(), _createBlock(_component_FormTrainingRegionAnchorItem, {
                        key: 1,
                        trainingKeyField: _ctx.trainingKeyField,
                        documentClassId: _ctx.documentClassId,
                        regionAnchorOfFormTraining: _ctx.regionAnchorOfFormTraining,
                        booleanOptions: _ctx.booleanOptions,
                        onTestRegionAnchor: _ctx.testingData,
                        onOnFocusField: _ctx.onFocusField,
                        onOnMarkRegion: _ctx.onMarkRegion
                      }, null, 8, ["trainingKeyField", "documentClassId", "regionAnchorOfFormTraining", "booleanOptions", "onTestRegionAnchor", "onOnFocusField", "onOnMarkRegion"]))
                    : (tab.id === 2)
                      ? (_openBlock(), _createBlock(_component_FormTrainingItemFields, {
                          key: 2,
                          trainingKeyField: _ctx.trainingKeyField,
                          documentClassId: _ctx.documentClassId,
                          tables: _ctx.tables,
                          itemFieldOfRegionInFormTraining: _ctx.itemFieldOfRegionInFormTraining,
                          booleanOptions: _ctx.booleanOptions,
                          onTestItemField: _ctx.testingData,
                          onOnFocusField: _ctx.onFocusField,
                          onOnMarkRegion: _ctx.onMarkRegion
                        }, null, 8, ["trainingKeyField", "documentClassId", "tables", "itemFieldOfRegionInFormTraining", "booleanOptions", "onTestItemField", "onOnFocusField", "onOnMarkRegion"]))
                      : (tab.id === 3)
                        ? (_openBlock(), _createBlock(_component_FormTrainingTestTable, {
                            key: 3,
                            trainingKeyField: _ctx.trainingKeyField,
                            documentClassId: _ctx.documentClassId,
                            documentId: _ctx.documentId,
                            testFilter: _ctx.testFilter,
                            onOnMarkRegion: _ctx.onMarkRegion
                          }, null, 8, ["trainingKeyField", "documentClassId", "documentId", "testFilter", "onOnMarkRegion"]))
                        : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}